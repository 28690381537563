function changeLocale(){
    let root = document.getElementsByClassName('dropdown')[0];

    let toogleEl = root.getElementsByClassName('dropdown-toggle')[0];
    let menuEl = root.getElementsByClassName('dropdown-menu')[0];
        toogleEl.onclick = function()
        {
            console.log(root);
            // menuEl.classList.toggle('show');
            menuEl.style.display = 'flex';
        }
        window.addEventListener('click', function (e) {
            if(!e.target.closest('.dropdown')){
                menuEl.style.display = 'none';
            }
        }, false)
}
export default changeLocale;
