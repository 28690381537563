import React from 'react';
import BreadCrumbs from './breadcrumbs/Breadcrumbs-test';
import Submenu from './submenu/Submenu';
import Showbuttons from './show-buttons/Showbuttons';
import Blocker from './Blocker';


class Menu extends React.Component{
  constructor(props) {
    super(props);

    this.state ={ 
      data: {}, isFetching: true, error: null ,
      filteredData:[],
      blocked: '',
      id: '',
      // currentMenu: (this.props.mainPage)?'model':'',
      currentMenu: (this.props.mainPage)?'model':'',
      breadcrumbs: [],
      scroll:''
    };
      // this.state.currentMenu = (this.props.mainPage)?'model':'';

    this.handleBreadcrumbClick = this.handleBreadcrumbClick.bind(this);
    this.handleClickOuter = this.handleClickOuter.bind(this);
    this.handleSubmenuClick = this.handleSubmenuClick.bind(this);
    this.handleSubmenuUpdate = this.handleSubmenuUpdate.bind(this);
    this.handleBreadcrumbsUpdate = this.handleBreadcrumbsUpdate.bind(this);
    this.handleBreadAndMenuUpdate = this.handleBreadAndMenuUpdate.bind(this);
    this.handleScroll = this.handleScroll.bind(this);


    this.timer = '';
  }
  handleBreadcrumbClick(response){
    this.setState({
            currentMenu: response,
    });
  }
  handleBreadAndMenuUpdate(response){
    this.setState({
            breadcrumbs: response,
            // currentMenu: response[0].filter,
            // id: response[0].data.id,
            isBreadUpdate: true
    });
  }
  handleBreadcrumbsUpdate(response){
    this.setState({
            breadcrumbs: response
    });
  }
  handleSubmenuClick(response){
    this.setState({
      id: response.id,
      refresh: response.refresh
      // data: response.filtered,
    });
  }
  handleSubmenuUpdate(response){
    this.setState({
      data: response.data,
    });
  }
  handleCloseMenu(){
    this.setState({
      currentMenu: ''
    });
  }
  /**
   * Обработчик нажатия на любое место вне меню
   */
  handleClickOuter(e){
    const eventTarget = e.target;
    if (
      !(eventTarget.closest('.wrapper-materials')||
      eventTarget.closest('.elements'))
    ) {
      if(!this.props.mainPage) this.setState({currentMenu: ''})
    }
  }
  handleScroll(e){
    if(this.timer) clearTimeout(this.timer);
    setTimeout(()=>{
      
      //@TODO исправит ьвывод по 12 раз в setState 
      this.setState(prevState=>({scroll:window.scrollY}));
    },250);
  }
 componentDidMount() {
    document.addEventListener('click', this.handleClickOuter);
    document.addEventListener('scroll', this.handleScroll);
    console.log('fetch!!!');
    fetch(window.location.origin+'/'+window.locale+'/get-menu')
        .then(response => response.json())
        .then(result => {
          // Встраиваем в модели и материалы свойство show
          for (var key in result) {
            if(key!=='combination'){
              result[key].forEach((item)=>{
                item.show = true;
                item.active = false;
              })
            }
          }
          this.setState({data: result, isFetching: false })
        });
        // .catch(e => {
        //   console.log(e);
        //   this.setState({data: result, isFetching: false, error: e }));
        // });
  }


  // asyncCopyState(){
  //   if (!this.state.isFetching) return this.state.data
  // }

  render(){
    return [
      <BreadCrumbs
        mainPage              = {this.props.mainPage}
        scroll                = {this.state.scroll}
        idFilterEl            = {this.state.id}
        refresh               = {this.state.refresh}
        copyState             = {this.state.data}
        isFetching            = {this.state.isFetching}

        handleBreadcrumbClick = {this.handleBreadcrumbClick}
        handleBreadcrumbsUpdate = {this.handleBreadcrumbsUpdate}
        handleBreadAndMenuUpdate = {this.handleBreadAndMenuUpdate}
      />, 
      <Submenu 
        mainPage              = {this.props.mainPage}

        state                 = {this.state.data}
        isFetching            = {this.state.isFetching}
        currentMenu           = {this.state.currentMenu}
        breadcrumbs           = {this.state.breadcrumbs}
        handleSubmenuClick    = {this.handleSubmenuClick}
        handleSubmenuUpdate    = {this.handleSubmenuUpdate}
      />,
      <Showbuttons
        mainPage              = {this.props.mainPage}
        scroll                = {this.state.scroll}
        breadcrumbs           = {this.state.breadcrumbs}
        currentMenu           = {this.state.currentMenu}
      />,
      <Blocker
        currentMenu           = {this.state.currentMenu}
        handleCloseMenu  
      />
    ];
  }

}

export default Menu;