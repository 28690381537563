// Класс-фабрика для создания отдельных слайдеров
export class Sliders {
    /**
     * массив рутовых элементов
     * массив элементов 
     */
    constructor(roots){
        this.roots = roots;
        this.slidersArr = [];
        this.dispatcherTouch = this.dispatcherTouch.bind(this);
        this.dispatcherMouse = this.dispatcherMouse.bind(this);
        this.elForMouse = '';
        
    }

    init(){
        for (var i = 0; i < this.roots.length; i++) {
            let slider = new Slider(this.roots[i], this.roots[i].parentElement.dataset.id);
            this.slidersArr.push(slider);
            slider.init();

            document.addEventListener('touchstart', this.dispatcherTouch, false);

            document.addEventListener('touchmove', this.dispatcherTouch, false);

            document.addEventListener('touchend', this.dispatcherTouch, false);

            // Погружение события addEventListener(... ,true);
            products.addEventListener('mouseenter', this.dispatcherMouse, true);
            products.addEventListener('mouseout', this.dispatcherMouse, false);
            products.addEventListener('mousemove', this.dispatcherMouse, false);
        }
    }
    // выбор дитспетчера в массиве Slider
    dispatcher(e){
        let products = Array.from(this.roots);
        let productId = '';

        products.forEach((el)=>{
            if(e.target.closest('.product') && el.parentElement.dataset.id==e.target.closest('.product').dataset.id) {
                productId = el.parentElement.dataset.id;
            }
            });
        if(productId) this.slidersArr.find(el=>el.root.parentElement.dataset.id == productId).dispatcher(e);
    }
    dispatcherTouch(e){
        this.dispatcher(e)
    }
    dispatcherMouse(e){
        this.dispatcher(e);
        
    }

}

class Slider {

    constructor(root, id){
        this.root = root;
        this.id = id;
        

        this.N =  root.children.length;
        this.NF = 30;
        this.TFN = {
            'bounce-out': function(k,n, a = 2.75, b = 1.5) {
                let r = 1 - Math.pow(1 - k, a)*Math.abs(Math.cos(Math.pow(k, b)*(n + .5)*Math.PI))
                return r
            }
        };
        this.i = 0, this.x0 = null, this.locked = false, this.w, this.ini, this.fin, this.rID = null, this.anf, this.n;
        this.y0 = null;
        this. testX0 = null, this.testY0 = null;

        this.elForMouse = '';
        this.number = 0;
        this.dispatcher = this.dispatcher.bind(this);
    }
    
    init(){
        this.size();
        this.root.style.setProperty('--n', this.N);
        // Выделяем первую точку активной
        // 2 nextElementSiblin Обусловлено тем что перед my-slider-points стоит .for-mouse элемент
        this.root.nextElementSibling.children[0].classList.add('active');

        addEventListener('resize', this.size, false);
        // document.addEventListener('touchstart', this.dispatcher, false);

        // document.addEventListener('touchmove', this.dispatcher, false);

        // document.addEventListener('touchend', this.dispatcher, false);

    }
    stopAni() {
        cancelAnimationFrame(this.rID);
        this.rID = null
    };

    ani(cf = 0) {
        this.root.style.setProperty('--i', this.ini + (this.fin - this.ini)*this.TFN['bounce-out'](cf/this.anf, this.n));

        if(cf === this.anf) {
            this.stopAni();
            return
        }

        this.rID = requestAnimationFrame(this.ani.bind(this, ++cf))
    };

    unify(e) {	return e.changedTouches ? e.changedTouches[0] : e };

    lock(e) {
        this.x0 = this.unify(e).clientX;
        this.y0 = this.unify(e).clientY;
        this.locked = true;
    };

    move(e) {
        
            if(this.locked){
            let dx = this.unify(e).clientX - this.x0;
            let s = Math.sign(dx),
                f = +(s*dx/this.w).toFixed(2);
                this.ini = this.i - s*f;
                if((this.i > 0 || s < 0) && (this.i < this.N - 1 || s > 0) && f > .2) {
                    this.i -= s;
                    f = 1 - f
                }
                this.fin = this.i;
                this.anf = Math.round(f*this.NF);
                this.n = 2 + Math.round(f)
                this.ani();
                this.x0 = null;
                this.locked = false;

                this.changePoint(this.i);
            }
            
    };

    drag(e) {
        
        // e.preventDefault();
        if(this.locked) {
            let dx = this.unify(e).clientX - this.x0, f = +(dx/this.w).toFixed(2);
            this.root.style.setProperty('--i', this.i - f);
        }
        
    };
 
    changePoint(index){
        // Стираем все активные точки
        let points = this.root.nextElementSibling.children;
        for (let i=0; i<points.length; i++){
            points[i].classList.remove('active');
        }
        // Выделяем точку активной фото темно-синим
        this.root.nextElementSibling.children[index].classList.add('active');
    }

    changePointNull(){

    }

    size() {
        this.w = document.getElementsByClassName('product')[0].scrollWidth;
    };
    
    lazyLoader(number){
        let productId = this.root.parentElement.dataset.id;
        let src=window.location.origin+'/img/hats/'+productId+'/';
        let arrImg = this.root.getElementsByTagName("IMG");
        arrImg[number].src = src+(number+1)+'.jpg';
    }

    compare(newNumber){
        if (newNumber!=this.number) {
            this.number = newNumber;
            this.root.style.setProperty('--i', newNumber);

            this.lazyLoader(newNumber);
            this.changePoint(newNumber);
        }
    }

    dispatcher(e){
        if (e.type === 'touchstart'){
            this.testX0 = this.unify(e).clientX;
            this.testY0 = this.unify(e).clientY;
            if (e.target===this.root) this.lock(e);
        }
        if (e.type === 'touchmove'){
            let dx = this.unify(e).clientX - this.testX0;
            let dy = this.unify(e).clientY - this.testY0;
            if ((Math.abs(dy)<Math.abs(dx))&&(e.target===this.root)){
                this.drag(e);
            }
            
        }
        if (e.type === 'touchend'){
            let dx = this.unify(e).clientX - this.testX0;
            let dy = this.unify(e).clientY - this.testY0;
            if ((Math.abs(dy)<Math.abs(dx))&&(e.target===this.root)){
                this.move(e);
            }
            this.lazyLoader(this.i);
        }

        if(e.type=='mouseenter'){
            if(e.target.closest('.product')){
                this.elForMouse = e.target.closest('.product').dataset.id;
            }
        }
        if(e.type=='mouseout'){
            console.log(e);
            if(e.target.classList.contains('my-slider')||e.target.classList.contains('products'))
            {
                this.root.style.setProperty('--i', 0);
            }
            // if(e.target.classList.contains('product')){
            //     this.root.style.setProperty('--i', 1);
            // }
        }
        if(e.type=='mousemove'){
            let product = e.target.closest('.product');
            let bounds = product.getBoundingClientRect(); 
                //@TODO OffsetX - слабая поддержка браузерами?
                // console.log('move', e.offsetX);
                // console.log(product.offsetWidth);
                let newNumber = 0;
                let posX = e.clientX - bounds.left;

                
                


                if(posX<=(product.offsetWidth/3)*1) newNumber = 0;
                if(posX>(product.offsetWidth/3)*1 && posX<(product.offsetWidth/3)*2) newNumber = 1;
                if(posX>=(product.offsetWidth/3)*2) newNumber = 2;


                this.compare(newNumber);
        }
    } 
}
