"use strict";
// Vendor libraries
import React from 'react';
import ReactDOM from 'react-dom';
// My libraries
import {orientationScreen} from "./handlers";
// React components
import Menu from './components/menu';
// Vanilla components
import {Sliders} from "./components/slider/slider";
import Locales from "./components/locales/locales"

class App{
  constructor(){
    this.mainPage = document.getElementById('menumainpage');
    this.menu = document.getElementById('menu');
    this.orientation = orientationScreen();
    this.lazyLoading = this.lazyLoading.bind(this);
    this.dispatcher = this.dispatcher.bind(this);
  }

  init(){
    this.listeners();
    this.initComponents(
      [this.menu, this.mainPage]
    );
    Locales();
  }

  initComponents(components){
    if (components[0]){
      ReactDOM.render(
        <Menu
          orientation={this.orientation}
        />,
        components[0]
      );
    }
    
    if (components[1]){
      ReactDOM.render(
        <Menu 
          mainPage={true}
          orientation={this.orientation}
        />,
        components[1]
      );
    }
  }

  listeners(){
    document.addEventListener("DOMContentLoaded", this.dispatcher);
    // Наблюдатель для lazyloading
    // Статья https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video/
  }

  /**
   * <img 
   *  class="lazy" 
   *  src="placeholder-image.jpg" 
   *  data-src="image-to-lazy-load-1x.jpg" 
   *  data-srcset="image-to-lazy-load-2x.jpg 2x, 
   *  image-to-lazy-load-1x.jpg 1x" 
   *  alt="I'm an image!"
   * >
   */

  lazyLoading(){
    // 1234
    let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    let active = false;

    const lazyLoad = function() {
      if (active === false) {
        active = true;

        setTimeout(function() {
          lazyImages.forEach(function(lazyImage) {
            if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
              lazyImage.src = lazyImage.dataset.src;
              lazyImage.srcset = lazyImage.dataset.srcset;
              lazyImage.classList.remove("lazy");

              lazyImages = lazyImages.filter(function(image) {
                return image !== lazyImage;
              });

              if (lazyImages.length === 0) {
                document.removeEventListener("scroll", lazyLoad);
                window.removeEventListener("resize", lazyLoad);
                window.removeEventListener("orientationchange", lazyLoad);
              }
            }
          });

          active = false;
        }, 200);
      }
    };

    lazyLoad();
    
    document.addEventListener("scroll", lazyLoad);
    window.addEventListener("resize", lazyLoad);
    window.addEventListener("orientationchange", lazyLoad);

  }

  dispatcher(e){
    if (e.type === "DOMContentLoaded") {
      if (location.href!=='https://guxen/'&&location.href!=='https://guxen/product/'){
        // location.hash = "#materials";
      }
      this.lazyLoading();
      let slidersArr =  document.getElementsByClassName('my-slider');
      let slidersContainer = new Sliders(slidersArr);
      slidersContainer.init();
    }
  }
}

const app = new App();
app.init();