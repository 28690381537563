import React from 'react';
class ShowButtons extends React.Component{
    count(arr){
      let i=0;
      arr.forEach(el=>{
        if(el.data==null) i++
      })
      return i;
    }

    render(){
      let href = window.location.origin+'/catalog/';
      let breadEl=this.props.breadcrumbs;
      
      breadEl.forEach((el,i)=>{
        if(el.data){
           href += el.filter+'-'+el.data.name+'/';
          }
          
      })
      // if(this.props.mainPage){
      //   // if (this.props.currentMenu&&this.count(this.props.breadcrumbs)!=2&&this.props.scroll>=400&&this.props.scroll<=912){
      //   if (this.props.currentMenu&&this.count(this.props.breadcrumbs)!=2){
      //     return(
      //       <div className="show-buttons-wrapper main-page">
      //         <span className="show-buttons">
      //           <a href={href}>Показать</a>
      //         </span>
      //       </div>
      //     );
      //   } 
      //   return null;
      // } else {
        let nullBread = this.count(this.props.breadcrumbs)==2;
        if (this.props.currentMenu){
            let close;
            if (window.locale=='ru'){
                close = 'Закрыть';
            } else {
                close = 'Close';
            }
          return(
            <div className="show-buttons-wrapper">
              <span className="show-buttons">
                  {/* {!nullBread && <a class="show" href={href}>Показать</a>} */}
                  <span 
                    class="close"
                    onClick={this.props.handleCloseMenu}
                  >
                      {close}
                  </span>
              </span>
            </div>
          );
        } 
        return null;
      // }
    }
}

// const Showbuttons = (props)=>{

//   let breadEl=props.breadcrumbs;
//   let href = '';
//   if(props.combination) props.combination.find((item)=>{
//     let cond0=breadEl[0].data?breadEl[0].data.id:null;
//     let cond1=breadEl[1].data?breadEl[1].data.id:null;
//       if((item['id_'+breadEl[0].filter]==cond0) && (item['id_'+breadEl[1].filter]==cond1)) href = item.id
//   })
//   return(
//     <div className="show-buttons">
//       <a href={window.location.origin+'/catalog/'+href}>Показать</a>
//     </div>
//   );
// }
ShowButtons.defaultProps = {breadcrumbs: [{filter:'', data:null}], combination: []}
export default ShowButtons;