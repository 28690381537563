import React from 'react';


function BreadCrumbsMain(props){
    let arr = []
    let minimize = false;
    if (props.scroll>133 && !props.mainPage){
        minimize = true;
    }
    if(props.state) props.state.forEach(el=>{
        let url = '';
        let name = '';

        if(el.data == null){
            let str = window.location.origin;
            url = (el.filter=='model')? str + '/img/hats/377/1.jpg': str + '/img/materials/meh.jpg';
            if(el.filter == 'model'){
                if (window.locale == 'ru'){
                    name = 'Модели';
                } else {
                    name = 'Models';
                }
            } else {
                if (window.locale == 'ru') {
                    name = 'Материалы';
                } else {
                    name = 'Materials';
                }
            }
            
        } else {
            url = window.location.origin+'/img/' + el.filter + 's/' + el.data.name + '.jpg';
            name = el.data.caption;
        }

        arr.push(
            <BreadElement 
                el      = {el.data}
                currentMenu = {el.filter}
                handler = {props.handler}
                url={url}
                name={name}
                scroll={props.scroll}
                minimize={minimize}
            />
        )   
    })
   
    return (
        <div 
            className='wrapper-materials' 
            onClick={props.handler}
        >
            {arr}
        </div>
    );
}
function BreadElement(props){
  return (
    <div 
        className="filter-button"
        // data-id={props.el.idFilterEl}
        data-name={props.currentMenu}
        onClick={props.handler}
    >
        {/* {!props.minimize&& */}
            <img 
            className={!props.el?"hide":""} 
            src={props.url} alt=""
            ></img>
        {/* } */}
        <span>{props.name}</span>
    </div>
  );
}


class BreadCrumbs extends React.Component{
    constructor(props) {
      super(props);
      this.state ={ 
        filterName: (this.props.mainPage)?'model':'',
        // для того чтобы вызвать 1 раз
        flag: false,
        breadcrumbs: [],
      };
      this.handleClick = this.handleClick.bind(this);
      this.calculate = {};
      // Первоначальная загрузка
      this.calculate = new Calculate(this.state.breadcrumbs, this.props.copyState); 
    }

    // ловим обновления по fetching (после загрузки state по сети)
    // и по id с submenu
    componentDidMount(){
      // console.log(this.props.isFetching);
    }
    componentDidUpdate(prevProps) {

      if (!this.state.flag && !this.props.isFetching){
        this.calculate.copyState = this.props.copyState;
        this.setState(state=>({breadcrumbs: this.calculate.dBCalc()}),
          ()=>{
            this.props.handleBreadAndMenuUpdate(this.state.breadcrumbs);
            // сбрасываем флаг, т.к. выполнить код выше нужно только лишь раз
            this.setState(prevState =>({
              flag: true,
            }));
          }
        );
      }

      // не выполнять первый раз
      // ловим обновления по id Submenu
      if (this.props.idFilterEl != prevProps.idFilterEl) {
        this.calculate.copyBread = [...this.state.breadcrumbs];
        this.setState(state=>(
          {
            breadcrumbs: this.calculate.mainCalc(
              this.props.idFilterEl, 
              this.state.filterName, 
              this.props.copyState
              )
          }),
          ()=>{
            this.props.handleBreadcrumbsUpdate(this.state.breadcrumbs)
          }
        );
      }
      
    }
    /**
     * Клик по верхним кнопкам
     */
    handleClick(e) {
        /**
         * Так как события удаляются после обработки (Класс SyntheticEvent) нужно 
         * создать ссылку на событие, чтобы к нему можно было по прежнему обращаться
         */
        const eventTarget = e.target;
        if (!eventTarget.classList.contains('wrapper-materials')){
          let name = eventTarget.closest('.filter-button').dataset.name;

            this.props.handleBreadcrumbClick(name);
            this.setState({filterName: name})
        }
    }

    getName(current){
      if (current=='model'){
        return 'material';
      }
      return 'model';
    }
    
   

    render(){
        return (
            <BreadCrumbsMain
              handler = {this.handleClick}
              state   = {this.state.breadcrumbs}
              scroll  = {this.props.scroll}
              mainPage = {this.props.mainPage}
            />
          );
      }
}

// Занимается подстановкой значений в breadcrumbs
// в зависимости
class Calculate {
  constructor(breadcrumbs=[], copyState=[]) {
    this.copyBread = [...breadcrumbs];
    this.copyState = {...copyState}
  }

  findFilterById(obj, id){
    for (let i in obj){
        if(obj[i].id == id) return obj[i];
    }
  }
  findFilterByName(obj, name){
    for (let i in obj){
        if(obj[i].name == name) return obj[i];
    }
  }
  count(arr){
    let i=0;
    arr.forEach(el=>{
      if(el.data==null) i++
    })
    return i;
  }
  getName(current){
    if (current=='model'){
      return 'material';
    }
    return 'model';
  }
  pushNull(index, filterName){
    this.copyBread[index] =
        {
          filter: this.getName(filterName), 
          data: null
        };
  }
  push(index, filterName, filterObj){
    this.copyBread[index] =
        {
          filter: filterName, 
          data: filterObj
        };
  }

  mainCalc(id, filterName, state, isEqual){
    let copyState = state[filterName];
    let filterObj = (id)?this.findFilterById(copyState, id):null;

     if (this.count(this.copyBread) != 2){
      for (let i = 0; i<this.copyBread.length; i++){
          let el = this.copyBread[i];
          
          if ((i==0)&&(el.filter==filterName)) {
              this.push(0, filterName, filterObj);
              this.pushNull(1, filterName)
          } 
          if ((i==1)&&(el.filter==filterName)) {
              this.push(1, filterName, filterObj);
          }
      }
    } else{
      this.push(0,filterName, filterObj);
      this.pushNull(1, filterName)
    }
    return this.copyBread;
  }

  dBCalc(){
    let breadObj = window.breadLoadPage;
    let newObj=[];

    breadObj.map(el=>{
      newObj.push(
        {
          'filter': el.filter, 
          'data': el.data?this.findFilterByName(this.copyState[el.filter], el.data):null
        }
      )
      
         
        
    })
    this.copyBread=newObj;
    return newObj

  }
  
}
 
export default BreadCrumbs;
