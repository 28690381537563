import React from 'react';

function SubmenuMain(props){
  let show = (props.mainPage)?'flex':'none';
  // function searchActive(arr){
  //   let elem = '';
  //   elem = arr.find(el=>el.active==true);
  //   if(elem) return elem;
  //   return null;
  // }
  return(
    <div 
      className="wrapper-elements" 
      style={{display: props.currentMenu ? 'flex' : show }}
    >
      <div className="elements" data-name={props.currentMenu}>
        {/* Здесь вместо currentMenu должно быть filteredData */}
        {props.state[props.currentMenu].map((el=>
          <SubMenuEl 
            key={el.id+props.currentMenu} 
            el      = {el}
            currentMenu = {props.currentMenu}
            handler = {props.handler}
            breadcrumbs = {props.breadcrumbs}
          />
        ))} 
      </div>
        {/* <div className="description">{searchActive(props.state[props.currentMenu])}</div> */}
    </div>
  );
}

function SubMenuEl(props){
  let classList = ''; 
  if(!props.el.show) classList = 'hide-element';
  if(props.el.active) classList = 'active';
  // if(props.keyForCss==props.el.id+props.currentMenu) classList = 'active';

  let href = window.location.origin+'/'+window.locale+'/catalog/';
  let breadEl=props.breadcrumbs;
  
  if (props.breadcrumbs) breadEl.forEach((el,i)=>{
    if(el.data){
       href += el.filter+'-'+el.data.name+'/';
      }
      
  })

  return (
    <a href={href}
        className={"material-item " + classList} 
        data-id={props.el.id}
        onClick={props.handler}
    >
        <img data-name="plash" src={window.location.origin+'/img/'+props.currentMenu+'s/'+props.el.name+'.jpg'} alt=""></img>
        <span><b>{props.el.caption}</b></span>

        <div className="close"></div>
    </a>
  );
}

class Submenu extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      block:'',
      id:'',
      flag: false,
      data: {},
      flag: false,
    }
    this.handleGetByFilter = this.handleGetByFilter.bind(this);
  }

  clear(arr){
    arr.forEach(el=>{
      el.active=false
    })
    return arr
  }
  
  // ловить обновления только по Bread
  componentDidUpdate(prevProps) {
    // bootload state (1 раз) из главного компонента
    if(!this.state.flag && !this.props.isFetching){
      this.setState(state=>({data: this.props.state}),()=>{
        this.setState(prevState =>({
          flag: true,
        }));
      })
    }
    // обновление по изменению bread
    if (this.state.flag && this.props.breadcrumbs!=prevProps.breadcrumbs){
      let newState = {...this.state.data}    

      this.props.breadcrumbs.forEach(breadEl=>{
        this.clear(newState[breadEl.filter]);
        newState[breadEl.filter].find(el=>{
          if(breadEl.data) {
            if (el.id==breadEl.data.id) el.active = true
          }
        });
      });

      this.setState(prevState=>({
        data: this.filter(newState, this.props.breadcrumbs) 
      }));
    }

    // обновление id по смене currentMenu
    if (this.state.flag && this.props.currentMenu!=prevProps.currentMenu){
      // let current = this.props.currentMenu;
      //   let filterFromBreads = this.props.breadcrumbs.find(el=>
      //     {
      //       return el.filter==current
      //     });
      //   this.setState(state=>({id: (filterFromBreads.data)?filterFromBreads.data.id:''}),()=>{
      //     this.props.handleSubmenuClick({
      //       id: this.state.id
      //     });
      //   });
    }

    
  
}

  // переписать функцию
  // добавить назначение активного элемента из массива bread 
  handleGetByFilter(e){
    const eventTarget = e.target;
    let id = eventTarget.closest('.material-item').dataset.id;
    // let newState = {...this.props.state}    
    

    // // Здесь была проблема 
    // this.clear(newState[filterName], id);
    
    // let elem = newState[filterName].find(el=>el.id==id);
    // elem.active= !elem.active
    // if (!elem.active) id = '';
    if (this.state.id == id) id = '';
    this.setState(state=>({
      id: id
    }),()=>{

      this.props.handleSubmenuClick({
        id: id
    });

    })
    this.setState({flag: false});

    
    
  }

  testHandleGetByFilter(e){
   
  }

  filter(newState, breads){
    // let copyState = {...this.props.state};
    let copyState = newState;
    function fill(filter){
      copyState[filter].forEach((item)=>{
        item.show = true;
      })
    }
    function clear(filter){
      copyState[filter].forEach((item)=>{
        item.show = false;
      })
    }
    function getName(current){
      if (current=='model'){
        return 'material';
      }
      return 'model';
    }
    // функция делает видимым некоторые элементы противоположного фильтра
    function filterProcess(breads){
      // перебор по комбинациям
        copyState.combination.forEach((item)=>{
          if(item['id_'+breads[0].filter]==((breads[0].data)?breads[0].data.id:null)) {
            // если ид эл-та комбинации совпадает с id выбранного элемента
            // то делаем его видимым
            copyState[breads[1].filter]
              .find(el=>{
                if(el.id==item['id_'+breads[1].filter]) el.show=!el.show;
              });
          }
          
        })
    }

    // запретить фильтровать первый элемент
    
    // if ((getName(filterName)!=this.props.breadcrumbs[0].filter)||(this.props.breadcrumbs[0].data==null)){

      // если прилетел пустой id по filter element то заполнять противоположный
      // if(!id){
        // fill(getName(filterName));
      // } else{
        // fill(filterName);
      // }
        
        // если прилетел непустой id по filter element то очищать фильтры и фильтровать противоположные
      // if(id) {

      // Работает так - если текущий breadEl пустой то заполнять,
      // если нет, то если breadEl = 1 то чистить и фильтровтаь
        if(breads.length>0){
          breads.forEach((el,index)=>{
              fill(el.filter);
              
              if(index==1){
                if(breads[0].data) clear(el.filter);
              }
            });
            if(breads[0].data) filterProcess(breads);
        }
      // }
    // }

    return copyState 
  }


  render(){
    if(this.props.state.hasOwnProperty('model')&&this.props.currentMenu){
      return [
        <SubmenuMain 
          mainPage      = {this.props.mainPage}
          state         = {this.props.state}
          id            = {this.state.id}
          currentMenu   = {this.props.currentMenu}
          handler       = {this.handleGetByFilter}
          breadcrumbs   = {this.props.breadcrumbs}
        />
      ];
    } else{
      return null;
    }
  }

}



export default Submenu;